<template>
  <b-card title="Edit Holiday">
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <b-form-group
              label="Name"
              description="Name"
            >
              <b-form-input
                v-model="form.name"
                placeholder="Holiday Name"
              ></b-form-input>
          </b-form-group>

          
        </b-col>
        <b-col cols="6">

          <b-form-group
              label="Holiday"
              description="Holiday"
            >
              <b-form-datepicker dropright v-model="form.holidayDate"></b-form-datepicker>
          </b-form-group>

          <b-btn @click="updateHoliday" class="float-right" variant="primary">
            Update
          </b-btn>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';

export default {
  
  data() {
    return {
      form: {
        name: '',
        holidayDate: ''
      }
    }
  },
  mounted() {
    this.getHolidays();
  },
  methods: {

    getHolidays() {
      axios.get('sales-and-purchase/holidays/'+this.$route.params.id).then((response) => {
        console.log(response)
        this.form = response.data.data
        console.log({response})
      }).catch((e) => {
        console.log(e);
      })
    },
    updateHoliday(){
      axios.put('sales-and-purchase/holidays/'+this.$route.params.id, this.form).then((response) => {
        this.$bvToast.toast(`You have successfully updated a Holiday ${this.form.name} ${this.form.holidayDate}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        this.$bvToast.toast(`You have failed to update a Holiday ${this.form.name} ${this.form.holidayDate}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })

    },
    
  }
}
</script>
